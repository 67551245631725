//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { bgimgUrl2 } from '/src/utils/config.js';	
import {
	getRegister,
	getSchoolInfo,
	checkUser,
	getNum,
	uploadData,
} from "../../api";
export default {
	name: "index",
	data() {
		return {
			bgimgUrl2:'',
			choose: true,
			regin: "",
			reginName: [], //区域数组
			selects: "",
			statusName: [], //学校数组
			grade: "", //所属年纪
			gradeName: [], //年级数组
			parentName: "", //家长姓名
			tutorName: "", //导师姓名
			userName: "", //姓名
			//tetherName:'',//老师姓名

			id: "",
			code: "",
		};
	},
	components: {},
	created() {
		this.bgimgUrl2 = bgimgUrl2;
		this.get_schoolInfo();
		// 参数 1 :老师 2：家长
		this.id = this.$route.query.id;
		if (this.id == 1) {
			this.choose = 1;
		} else if (this.id == 2) {
			this.choose = 2;
		} else if (this.id == 3) {
			this.choose = 3
		}
	},
	methods: {
		//验证用户信息
		startDati() {
			checkUser({
				identity: this.$route.query.id, // 区分老师与家长 1：老师，2：家长
				area: this.regin, //区域id
				school_id: this.selects, //学校id
				grade_id: this.grade,
				// teacher:this.tutorName,//辅导员姓名
				username: this.userName,
			}).then((res) => {
				if (res.data.code == 1) {
					this.$router.push("/paihang");
				} else {
					this.$toast.fail(res.data.msg);
				}
				console.log(res.data);
			});
		},
		//选择区域
		reginname() {
			var arr = this.reginName;
			for (var i = 0; i < arr.length; i++) {
				if (this.regin == arr[i].id) {
					this.statusName = arr[i].schoolArr;
					console.log(arr[i].schoolArr);
				}
			}
			console.log(this.regin);
		},
		// 选择学校
		schoolname() {
			var arr = this.statusName;
			for (var i = 0; i < arr.length; i++) {
				if (this.selects == arr[i].id) {
					this.gradeName = arr[i].children;
				}
			}
			console.log(this.selects);
		},
		//选择年级
		gradename() {
			console.log(this.grade);
		},
		//获取学校信息
		get_schoolInfo() {
			console.log("1");
			getSchoolInfo().then((res) => {
				var arr = res.data.data;
				let nameArr = arr.map((item) => {
					return {
						name: item.name,
						id: item.id,
						schoolArr: item.children
					};
				});
				//区域信息
				this.reginName = nameArr;
				//console.log(nameArr)
				console.log(arr);
			});
		},

		//填写信息
		get_register() {
			let str = "";
			if (this.id == 1) {
				str = {
					identity: this.$route.query.id, // 区分老师与家长 1：老师，2：家长
					area: this.regin, //街道id
					school_id: this.selects, //学校id
					//  teacher:this.tutorName,//辅导员姓名
					username: this.userName,
				};
			} else if (this.id == 2) {
				str = {
					identity: this.$route.query.id, // 区分老师与家长 1：老师，2：家长
					area: this.regin, //街道id
					school_id: this.selects, //学校id
					grade_id: this.grade,
					//  teacher:this.tutorName,//辅导员姓名
					username: this.userName,
				};
			} else if (this.id == 3) {
				str = {
					identity: this.$route.query.id, // 区分老师与家长 1：老师，2：家长
					area: this.regin, //街道id
					username: this.userName,
				}
			}
			getRegister(str).then((res) => {
				this.code = res.data.code;
				console.log(res.data.code);
			});
		},
	},
};
